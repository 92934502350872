import React, { useState } from 'react';

function App() {
    const [message, setMessage] = useState('');
    const [chatLog, setChatLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isTyping, setIsTyping] = useState(false); // To track when typing is happening

    const sendMessage = async () => {
        if (!message.trim()) {
            alert('Please enter a message.');
            return;
        }

        // Show user input immediately in the chat log
        setChatLog((prevLog) => [...prevLog, { role: 'user', text: message }]);
        setMessage(''); // Clear the input field

        setLoading(true);
        setIsTyping(true); // Start typing

        try {
            const response = await fetch('https://askthedev.ai:3009/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ message }),
            });

            const data = await response.json();

            // Start typing effect for the bot's response
            startTypingEffect(data.reply);
        } catch (error) {
            console.error('Error sending message:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to simulate typing effect for bot's response
    const startTypingEffect = (response) => {
        let index = -1;

        // Initialize the bot's response as an empty string in the chat log
        setChatLog((prevLog) => [...prevLog, { role: 'atd-dev', text: '' }]);

        const interval = setInterval(() => {
            if (index < response.length) {
                // Update the last message in the chat log (bot's message)
                setChatLog((prevLog) => {
                    const updatedLog = prevLog.map((entry, i) =>
                        i === prevLog.length - 1
                            ? { ...entry, text: entry.text + response.charAt(index) }
                            : entry
                    );
                    return updatedLog;
                });
                index++;
            } else {
                clearInterval(interval);
                setIsTyping(false); // End typing once response is done
            }
        }, 50); // Adjust typing speed (50ms between characters)
    };

    // Submit the form on Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !loading && !isTyping) {
            sendMessage();
        }
    };

    return (
        <div className="chat-container">
            <div className="logo-wrap">
                <img src="logo.png" alt="ask the dev" />
            </div>
            <div className="chat-box">
                
                <div className="message-box" id="message-box">
                    {chatLog.map((entry, index) => (
                        <div
                            key={index}
                            className={entry.role === 'atd-dev' ? 'bot-message' : 'user-message'}
                        >
                            <b>{entry.role === 'atd-dev' ? 'D̸̗̻͌e̸͈̋v̸͕̏' : 'You'}:</b> {entry.text}
                        </div>
                    ))}
                   
                </div>

                <div className="input-box">
                    <input
                        type="text"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                        placeholder="Ask the dev..."
                        disabled={loading || isTyping}
                    />
                    <button onClick={sendMessage} disabled={loading || isTyping}>
                        {loading ? 'Sending...' : 'Send'}
                    </button>
                </div>
            </div>

            <a href="#" className="ca">
                $ATD - 4v14k2vBuoNSDLnMa12qK2pqokp8K7ZpVU7uWjDgpump
            </a>

            <style>{`
                * {
                    margin: 0;
                    padding: 0;
                    box-sizing: border-box;
                }

                body, html {
                    height: 100%;
                    font-family: Arial, sans-serif;
                    background: url('bg.jpg') no-repeat top center;
                    background-size: cover;
                    color: white;
                }

                .chat-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    flex-wrap: wrap;
                    align-content: center;
                }

                .chat-box {
                    background: rgba(255, 255, 255, 0.1);
                    padding: 20px;
                    border-radius: 0px;
                    width: 100%;
                    max-width: 600px;
                }

                .chat-title {
                    text-align: center;
                    font-size: 24px;
                    margin-bottom: 20px;
                }

                .message-box {
                    height: 300px;
                    overflow-y: auto;
                    background: rgba(0, 0, 0, 0.3);
                    padding: 10px;
                    border-radius: 0px;
                    margin-bottom: 10px;
                }

                .user-message, .bot-message {
                    margin-bottom: 10px;
                }

                .user-message {
                    text-align: right;
                    color: #00d1b2;
                }

                .bot-message {
                    text-align: left;
                    color: #ff3860;
                }

                .input-box {
                    display: flex;
                }

                .input-box input {
                    flex: 1;
                    padding: 10px;
                    border: none;
                    border-radius: 0px;
                    outline: none;
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                }

                .input-box button {
                    padding: 10px 20px;
                    background-color: #ff0033;
                    border: none;
                    border-radius: 0px;
                    cursor: pointer;
                    color: white;
                    margin-left: 10px;
                }

                .input-box button:hover {
                    background-color: #ff1a45;
                }

                .logo-wrap {
                    flex: 0 0 100%;
                    text-align: center;
                }

                .ca {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    color: #ff1a45;
                    font-size: 14px;
                    font-style: italic;
                    text-decoration: none;
                    opacity: 1;
                    transition: 0.3s;
                }

                .ca:hover {
                    color: white;
                }
            `}</style>
        </div>
    );
}

export default App;